<template>
  <div class="tw-flex tw-items-center">
    <div class="tw-flex tw-items-center tw-min-w-0">
      <div class="tw-shrink-0">
        <UiAvatar
          :src="item.avatar"
          :text="item.username.substring(0, 2)"
          :to="item.url"
          size="sm"
        />
      </div>
      <a
        class="tw-ml-3 tw-min-w-0 md:tw-ml-4"
        :href="item.url"
        target="_blank"
      >
        <div
          class="tw-font-semibold tw-truncate md:tw-text-lg"
          :title="item.username"
        >
          @{{ item.username }}
        </div>
        <div class="tw-mt-1 tw-text-sm tw-text-gray-700 md:tw-text-base">
          {{ item.name }}
        </div>
      </a>
    </div>

    <div class="tw-shrink-0 tw-ml-auto tw-pl-2">
      <button
        v-if="dismissible"
        class="tw-block"
        @click="$emit('dismiss')"
      >
        <UiIcon
          name="close"
        />
      </button>

      <UiButton
        class="tw-w-full"
        variant="primary"
        :to="item.url"
      >
        {{ $t('general.view-profile') }}
      </UiButton>
    </div>
  </div>
</template>

<script>
import UiAvatar from '@/components/ui/UiAvatar.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: {
    UiAvatar,
    UiButton,
    UiIcon,
  },
  props: {
    dismissible: Boolean,
    value: Object,
  },
  computed: {
    item: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
