<template>
  <div :class="variants.base()">
    <UiIcon
      class="tw-h-20 tw-w-20 md:tw-h-24 md:tw-w-24"
      name="lock"
      solid
    />

    <div class="tw-flex tw-items-center tw-justify-center tw-space-x-3">
      <div
        v-if="images > 0"
        class="tw-flex tw-items-center tw-space-x-1"
      >
        <UiIcon
          class="tw-inline"
          :class="variants.icon()"
          name="image"
        />
        <span>{{ images }}</span>
      </div>
      <div
        v-if="videos > 0"
        class="tw-flex tw-items-center tw-space-x-2"
      >
        <UiIcon
          :class="variants.icon()"
          name="video"
        />
        <span>{{ videos }}</span>
      </div>
    </div>

    <UiButton
      :size="size"
      variant="primary"
      @click="$emit('unlock')"
    >
      {{
        price
          ? $t('general.unlock-post-for-x', [price])
          : $t('general.subscribe-to-see')
      }}
    </UiButton>
  </div>
</template>

<script>
import { tv } from 'tailwind-variants';

import UiIcon from '@/components/ui/UiIcon.vue';
import UiButton from '@/components/ui/UiButton.vue';

export default {
  components: {
    UiButton,
    UiIcon,
  },
  props: {
    images: Number,
    videos: Number,
    price: String,
    size: {
      type: [String, Object], // 'sm' | 'md'
      default: 'md',
    },
  },
  computed: {
    className() {
      return tv(
        {
          slots: {
            base: `
              tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center
              tw-text-gray-500 tw-bg-gray-200 tw-rounded-2xl
            `,
            icon: 'tw-inline',
          },
          variants: {
            size: {
              sm: {
                base: 'tw-p-2 tw-space-y-2',
                icon: 'tw-size-4',
              },
              md: {
                base: 'tw-py-5 tw-px-4 tw-space-y-6',
                icon: 'tw-size-6',
              },
            },
          },
        },
        {
          responsiveVariants: ['xl'],
        },
      );
    },
    variants() {
      return this.className({ size: this.size });
    },
  },
};
</script>
