<template>
  <UiCard class="tw-min-h-screen">
    <div class="bg-spots tw-flex tw-flex-col tw-grow tw-py-4">
      <!-- Stepper -->
      <div class="tw-w-full tw-max-w-screen-xl tw-mx-auto tw-mb-8 tw-flex tw-items-center tw-justify-between md:tw-mb-12">
        <div class="tw-grow tw-mx-8 tw-flex tw-justify-center">
          <ol
            class="
              tw-w-full tw-flex tw-items-center tw-justify-center tw-space-x-2
              tw-text-sm tw-font-medium tw-text-center tw-text-gray-500
              sm:tw-text-base sm:tw-space-x-4
            "
          >
            <li
              v-for="(step, index) in steps"
              :key="index"
              class="tw-flex tw-items-center"
              :class="{
                'tw-text-primary-500': index === currentStep - 1,
              }"
            >
              <span
                class="tw-flex tw-items-center tw-justify-center tw-w-5 tw-h-5 tw-mr-2 tw-text-xs tw-border tw-rounded-full tw-shrink-0"
                :class="index === currentStep - 1 ? 'tw-border-primary-500' : 'tw-border-gray-500'"
              >
                {{ index + 1 }}
              </span>

              <span class="sm:tw-hidden">{{ $t(step.short_title) }}</span>
              <span class="tw-hidden sm:tw-inline">{{ $t(step.title) }}</span>

              <UiIcon
                v-if="index < steps.length - 1"
                class="tw-shrink-0 tw-h-4 tw-w-4 tw-ml-2 sm:tw-ml-4"
                name="chevron-right"
              />
            </li>
          </ol>
        </div>

        <div
          class="tw-w-6"
        />
      </div>

      <div class="tw-grow tw-w-full tw-flex tw-flex-col tw-max-w-md tw-mx-auto">
        <template v-if="isLoaded">
          <PageVerifyProfile
            v-if="currentStep === 1"
            :user="user"
            :verification="verification"
            @update:user="user = $event"
            @next="loadInfo"
          />
          <PageVerifyPhoto
            v-else-if="currentStep === 2"
            :verification="verification"
          />
        </template>
      </div>
    </div>
  </UiCard>
</template>

<script>
import User from '@/components/models/User';
import Verification from '@/components/models/Verification';

import PageVerifyProfile from '@/components/pages/PageVerifyProfile.vue';
import PageVerifyPhoto from '@/components/pages/PageVerifyPhoto.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiCard from '@/components/ui/UiCard.vue';

export default {
  components: {
    UiCard,
    UiIcon,
    PageVerifyProfile,
    PageVerifyPhoto,
  },
  data() {
    return {
      currentStep: 1,
      steps: [
        { title: 'general.enter-your-information', short_title: 'general.information' },
        { title: 'general.verify-your-identity', short_title: 'general.identity' },
      ],
      user: null,
      verification: null,
      isLoaded: false,
    };
  },
  created() {
    Promise.all([
      this.loadUser(),
      this.loadInfo(),
    ]).then(() => {
      this.isLoaded = true;
    });
  },
  methods: {
    loadUser() {
      return new Promise((resolve, reject) => {
        this.$get(
          '/auth/me',
          (data) => {
            this.user = new User(data);
            this.$root.$i18n.locale = data.locale;
            resolve(data);
          },
          (errors) => {
            console.log(errors);
            reject(errors);
          },
        );
      });
    },
    loadInfo() {
      return new Promise((resolve, reject) => {
        this.$get(
          '/payouts/verification',
          (data) => {
            if (data.verification) {
              this.currentStep++;
            }

            this.verification = data.verification
              ? new Verification(data.verification)
              : null;
            resolve(data);
          },
          (errors) => {
            console.log(errors);
            reject(errors);
          },
        );
      });
    },
  },
};
</script>

<style scoped>
@media screen(md) {
  .bg-spots {
    background-image: url('@/assets/illustrations/spots.svg');
    background-repeat: no-repeat;
    background-position: center 10rem;
    background-size: 90%;
  }
}
</style>
