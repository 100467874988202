<template>
  <div
    v-if="post !== null"
    class="tw-w-full"
  >
    <div class="tw-w-full tw-flex tw-items-start tw-justify-between tw-mb-4 tw-whitespace-nowrap md:tw-mb-6">
      <div class="tw-flex tw-items-center tw-min-w-0">
        <UiAvatar
          :online="post.user.is_online"
          :size="{ initial: 'xs', sm: 'sm' }"
          :src="post.user.avatar_url"
          :text="post.user.initials"
          :to="post.user.url"
        />

        <div class="tw-ml-2 tw-flex tw-flex-col tw-items-start tw-overflow-hidden">
          <UiUsername
            :user="post.user"
          />

          <div class="tw-text-sm tw-text-gray-700 md:tw-text-base">
            <RouterLink
              :to="'/' + post.user.username"
              class="tw-truncate"
            >
              @{{ post.user.username }}
            </RouterLink>
            -
            <span>{{ post.ago }}</span>
          </div>
        </div>
      </div>

      <div class="-tw-mr-1">
        <UiDropdown>
          <UiDropdownItem
            icon="paper"
            @click.prevent="copyLink"
          >
            {{ $t('general.copy-link') }}
          </UiDropdownItem>
          <UiDropdownItem
            v-if="isOwner"
            icon="edit-square"
            :to="'/posts/edit/' + post.id"
          >
            {{ $t('general.edit') }}
          </UiDropdownItem>
          <UiDropdownItem
              v-if="post.user.isCreator && !isOwner"
              icon="danger-circle"
              @click.prevent="post.user.report($store.state.currentUser.email, $root.$i18n.locale)"
          >
            {{ $t('general.report') }}
          </UiDropdownItem>
          <UiDropdownItem
            v-if="isOwner"
            icon="delete"
            @click.prevent="isDeleteModalOpen = true"
          >
            {{ $t('general.delete') }}
          </UiDropdownItem>
        </UiDropdown>
      </div>
    </div>

    <!-- Poll -->
    <div
      v-if="post.poll.length > 0"
      class="tw-mt-4"
    >
      <UiPoll
        :title="post.message"
        :options="post.poll"
        :showResults="post.hasVoted"
        @vote="vote"
      />
    </div>

    <!-- Message -->
    <div
      v-if="post.poll.length === 0"
      class="tw-w-full tw-mt-4 tw-overflow-hidden nl2br wrap md:tw-mt-6"
    >
      {{ post.message }}
    </div>

    <!-- Media -->
    <div
      v-if="!bare"
      class="tw-mt-4 md:tw-mt-6"
    >
      <UiPostMedia
        v-if="post.media.length > 0 || !hasAccess"
        v-model="post"
        :locked="!hasAccess"
        :sizes="sizes"
        squared
      />
    </div>

    <!-- Action buttons -->
    <div
      v-if="!bare"
      class="tw-mt-4 tw-w-full tw-px-3 md:tw-px-0"
    >
      <UiPostInteractions
        v-model="post"
        :hasAccess="hasAccess"
        @preview="$emit('preview', post)"
      />
    </div>

    <UiAlertModal
      v-if="isDeleteModalOpen"
      :title="$t('general.delete-x-title', [$t('general.post')])"
      :message="$t('general.delete-x-message', [$t('general.post')])"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.yes-delete')"
      @cancel="isDeleteModalOpen = false"
      @confirm="del"
    />
  </div>
</template>

<script>
import Post from '@/components/models/Post';
import Payment from '@/components/models/Payment';

import UiUsername from '@/components/ui/UiUsername.vue';
import UiPoll from '@/components/ui/UiPoll.vue';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiDropdownItem from '@/components/ui/UiDropdownItem.vue';
import UiAlertModal from '@/components/ui/UiAlertModal.vue';
import UiPostMedia from '@/components/ui/UiPostMedia.vue';
import UiPostInteractions from '@/components/ui/UiPostInteractions.vue';

export default {
  components: {
    UiPostInteractions,
    UiPostMedia,
    UiAlertModal,
    UiDropdownItem,
    UiDropdown,
    UiAvatar,
    UiPoll,
    UiUsername,
  },
  data: function () {
    return {
      isDeleteModalOpen: false,
    };
  },
  props: {
    value: Post,
    bare: Boolean,
    sizes: [String, Object],
  },
  computed: {
    post: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    isOwner: function () {
      return (
        this.value && this.value.user.id === this.$store.state.currentUser.id
      );
    },
    hasAccess: function () {
      return this.post.hasAccess;
    },
    totalVotes() {
      let total = 0;
      for (let p of this.value.poll) {
        total += p.votes_count;
      }
      return total;
    },
  },
  methods: {
    calcPercent(id) {
      if (!this.post.hasVoted) {
        return 0;
      }

      for (let p of this.post.poll) {
        if (p.id == id) {
          return Math.round((p.votes_count / this.totalVotes) * 100);
        }
      }
      return 0;
    },
    del() {
      this.$post(
        '/posts/' + this.post.id,
        {
          _method: 'DELETE',
        },
        () => {
          this.$emit('del', this.post);
          this.post = null;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    copyLink() {
      const link = `${process.env.VUE_APP_APP_URL}${this.post.url}`;
      this.$copyText(link);
      this.$toast({
        title: this.$t('general.link'),
        description: this.$t('general.link-copied'),
        variant: 'success',
      });
    },
    vote(id) {
      if (this.post.hasVoted) {
        return;
      }
      this.$post(
        '/posts/' + this.post.id + '/' + id + '/vote',
        {},
        (data) => {
          this.post = new Post(data);
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    tip() {
      this.$buyItem({
        type: Payment.TYPE_TIP,
        post: this.post,
        user: this.post.user,
      });
    },
  },
};
</script>
