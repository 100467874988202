<template>
  <div>
    <UiNotFound v-if="isPostNotFound" class="tw-w-full not-found" />
    <UiCard v-if="post !== null">
      <UiNavigationBar
        bleed
        :title="$t('general.back')"
        @back="$router.go(-1)"
      />

      <UiPost
        v-model="post"
        :sizes="{ lg: '56vw', xl: '65vw' }"
        @del="del"
      />
      <UiComments
        v-if="post.hasAccess"
        class="tw-mt-4 -tw-mx-4 md:-tw-mx-6"
        :postId="post.id"
      />
    </UiCard>
  </div>
</template>

<script>
import Post from '@/components/models/Post';

import UiCard from '@/components/ui/UiCard.vue';
import UiComments from '@/components/ui/UiComments.vue';
import UiNavigationBar from '@/components/ui/UiNavigationBar.vue';
import UiNotFound from '@/components/ui/UiNotFound.vue';
import UiPost from '@/components/ui/UiPost.vue';

export default {
  data: function () {
    return {
      post: null,
      isPostNotFound: null,
    };
  },
  components: {
    UiCard,
    UiComments,
    UiNavigationBar,
    UiNotFound,
    UiPost,
  },
  computed: {
    hasAccess() {
      return this.post.hasAccess;
    },
  },
  mounted() {
    this.loadPost();
  },
  methods: {
    loadPost() {
      this.$get(
        '/posts/' + this.$route.params.id,
        (data) => {
          this.post = new Post(data);
        },
        (errors) => {
          this.isPostNotFound = true;
          console.log(errors);
        },
      );
    },
    del() {
      this.$router.replace('/');
    },
  },
};
</script>
