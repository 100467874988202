<template>
  <div
    class="tw-flex"
  >
    <UiNotFound v-if="isUserNotFound" class="tw-w-full not-found" />
    <div
      v-if="user != null"
      class="tw-w-full profile"
    >
      <UiCard>
        <UiNavigationBar
          bleed
          :title="user.name"
          @back="$router.go(-1)"
        >
          <UiDropdown>
            <UiDropdownItem
              icon="paper"
              @click.prevent="copyLink"
            >
              {{ $t('general.copy-link-to-profile') }}
            </UiDropdownItem>
            <UiDropdownItem
              v-if="user.isCreator && !isOwner"
              icon="danger-circle"
              @click.prevent="user.report($store.state.currentUser.email, $root.$i18n.locale)"
            >
              {{ $t('general.report') }}
            </UiDropdownItem>
            <UiDropdownItem
              v-if="!user.isCreator && !isOwner"
              icon="danger-circle"
              @click.prevent="userToBlock = user.username"
            >
              {{ $t('block-user.cta') }}
            </UiDropdownItem>
          </UiDropdown>
        </UiNavigationBar>

        <div>
          <UiProfileCover
            v-if="user.cover_url !== null"
            :sizes="{ lg: '56vw', xl: '65vw' }"
            :src="user.cover_url"
            :srcset="user.cover_variants"
          />

          <div
            class="tw-mt-4 md:tw-mt-0 md:tw-relative md:tw-mx-auto"
            :class="{ 'md:tw-mt-[-50px] md:tw-z-[3]': user.cover_url !== null }"
          >
            <div class="tw-flex tw-items-center tw-gap-3 md:tw-flex-col">
              <UiAvatar
                class="md:tw-ring-4 md:tw-ring-white"
                :size="{ initial: 'sm', md: 'lg' }"
                :src="user.avatar_url"
                :text="user.initials"
                :to="user.url"
              />

              <div class="tw-min-w-0 md:tw-text-center md:tw-w-full">
                <UiUsername
                  class="md:tw-justify-center"
                  :asLink="false"
                  :user="user"
                />

                <div class="tw-text-gray-500 tw-truncate">
                  {{ '@' + user.username }}
                  <span v-if="user.isCreator">
                    - {{ user.is_online ? $t('general.online') : $t('general.offline') }}
                  </span>
                  <span
                    v-if="user.is_online"
                    class="tw-inline-block tw-align-middle tw-ml-1 tw-h-[5px] tw-w-[5px] tw-rounded-full tw-bg-success-alt"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="user.isCreator"
              class="tw-flex tw-flex-col tw-items-center"
            >
              <div class="tw-mt-4 tw-flex -tw-mx-2 tw-divide-x tw-divide-gray-200">
                <div class="tw-px-6 tw-text-center">
                  <div class="tw-font-semibold tw-text-xl md:tw-text-2xl">
                    {{ $n(user.post_count, 'compact') }}
                  </div>
                  <div class="tw-text-gray-800">
                    {{ $tc('general.x-posts', user.post_count) }}
                  </div>
                </div>

                <div class="tw-px-6 tw-text-center">
                  <div class="tw-font-semibold tw-text-xl md:tw-text-2xl">
                    {{ $n(user.subscriber_count, 'compact') }}
                  </div>
                  <div class="tw-text-gray-800">
                    {{ $tc('general.x-fans', user.subscriber_count) }}
                  </div>
                </div>

                <div class="tw-px-6 tw-text-center">
                  <div class="tw-font-semibold tw-text-xl md:tw-text-2xl">
                    {{ $n(user.like_count, 'compact') }}
                  </div>
                  <div class="tw-text-gray-800">
                    {{ $tc('general.x-likes', user.like_count) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="user.bio || user.location || user.website"
          class="tw-mt-4 tw-space-y-3 tw-overflow-hidden md:tw-text-center"
        >
          <div
            v-if="user.bio"
            class="tw-whitespace-pre-line"
          >
            {{ user.bio }}
          </div>

          <div
            v-if="user.location"
            class="tw-text-gray-500"
          >
            {{ user.location }}
          </div>

          <div
            v-if="user.website"
          >
            <UiLink
              :href="user.website"
              target="_blank"
            >
              {{ user.website }}
            </UiLink>
          </div>
        </div>

        <!-- Interactions buttons -->
        <div class="tw-mt-6">
          <div
            v-if="isOwner"
            class="tw-mx-auto tw-max-w-md"
          >
            <div class="tw-flex tw-justify-center tw-space-x-3 md:tw-space-x-4">
              <UiButton
                class="md:tw-basis-0 tw-grow"
                icon="edit"
                variant="secondary"
                to="/settings"
              >
                {{ $t('general.edit-profile') }}
              </UiButton>
              <UiButton
                v-if="user.isCreator"
                class="md:tw-basis-0 tw-grow"
                icon="edit"
                variant="secondary"
                to="/settings/price"
              >
                {{ $t('general.edit-subscriptions') }}
              </UiButton>
            </div>
          </div>

          <div
            v-else
            class="tw-mx-auto tw-max-w-xl"
          >
            <div class="tw-flex tw-flex-wrap tw-gap-3 md:tw-justify-center md:tw-gap-4">
              <UiButton
                v-if="user.isSubscribed || currentUser.is_creator"
                class="tw-basis-0 tw-grow"
                :to="'/messages/' + user.username"
                variant="secondary"
              >
                {{ $t('general.message') }}
              </UiButton>
              <UiButton
                v-if="!user.isSubscribed && !isOwner && user.is_creator"
                class="tw-basis-0 tw-grow"
                icon="star"
                variant="primary"
                @click="subscribe"
              >
                {{ $t('general.subscribe') }}
              </UiButton>
              <UiButton
                v-if="user.is_creator && user.isSubscribed"
                class="tw-basis-full sm:tw-basis-0 sm:tw-grow"
                icon="wallet"
                variant="primary"
                @click="tip"
              >
                {{ $t('general.send-tip') }}
              </UiButton>
            </div>
          </div>
        </div>
      </UiCard>

      <!-- Content tabs -->
      <div class="tw-mt-2 tw-px-4 md:tw-mt-3 md:tw-px-0">
        <div
          v-if="isOwner"
          class="tw-z-bar tw-sticky tw-top-0 tw-pb-3 tw-bg-white md:tw-top-[--top] md:tw-pb-4 md:tw-bg-gray-50"
        >
          <div class="tw-max-w-xl tw-mx-auto tw-flex">
            <UiTabItem
              :active="postsType === types.active"
              icon="paper"
              @click="updatePosts(types.active)"
            >
              {{ $t('general.posts') }}
            </UiTabItem>
            <UiTabItem
              :active="postsType === types.scheduled"
              icon="calendar"
              @click="updatePosts(types.scheduled)"
            >
              {{ $t('general.scheduled') }}
            </UiTabItem>
            <UiTabItem
              :active="postsType === types.expired"
              icon="time-circle"
              @click="updatePosts(types.expired)"
            >
              {{ $t('general.expired') }}
            </UiTabItem>
          </div>
        </div>

        <div
          ref="postsContainer"
          class="tw-mt-1 md:tw-mt-2"
        >
          <UiPosts
            v-model="posts"
            layout="grid"
            :loading="isLoading"
            :sizes="{ initial: '30vw', lg: '20vw', xl: '22vw' }"
          />
        </div>
      </div>
      <UiBlockUser
        :username="userToBlock"
        @cancel="userToBlock = null"
        @success="onBlock"
      />
    </div>
  </div>
</template>

<script>
import Post from '@/components/models/Post';
import User from '@/components/models/User';
import Payment from '@/components/models/Payment';

import UiAvatar from '@/components/ui/UiAvatar.vue';
import UiBlockUser from '@/components/ui/UiBlockUser.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCard from '@/components/ui/UiCard.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiDropdownItem from '@/components/ui/UiDropdownItem.vue';
import UiLink from '@/components/ui/UiLink.vue';
import UiNavigationBar from '@/components/ui/UiNavigationBar.vue';
import UiNotFound from '@/components/ui/UiNotFound.vue';
import UiPosts from '@/components/ui/UiPosts.vue';
import UiProfileCover from '@/components/ui/UiProfileCover.vue';
import UiTabItem from '@/components/ui/UiTabItem.vue';
import UiUsername from '@/components/ui/UiUsername.vue';

export default {
  components: {
    UiAvatar,
    UiBlockUser,
    UiButton,
    UiCard,
    UiDropdown,
    UiDropdownItem,
    UiLink,
    UiNavigationBar,
    UiNotFound,
    UiPosts,
    UiProfileCover,
    UiTabItem,
    UiUsername,
  },
  data() {
    return {
      user: null,
      isUserNotFound: null,
      posts: [],
      page: 1,
      hasMore: false,
      isLoading: false,
      postsType: Post.TYPE_ACTIVE,
      isSubscriptionModalOpen: false,
      selectedSubscription: -1,
      userToBlock: null,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser.id;
    },
    isOwner() {
      return this.user.id === this.$store.state.currentUser.id;
    },
    username() {
      return this.$route.params.username;
    },
    types() {
      return {
        active: Post.TYPE_ACTIVE,
        scheduled: Post.TYPE_SCHEDULED,
        expired: Post.TYPE_EXPIRED,
      };
    },
  },
  watch: {
    username(oldV, newV) {
      if (oldV && newV && oldV !== newV) {
        this.reset();
        this.loadUser();
      }
    },
  },
  mounted() {
    this.loadUser();
    window.addEventListener('scroll', this.updateScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll);
  },
  methods: {
    updatePosts(type) {
      this.postsType = type;
      this.reset();
      this.loadPosts();
    },
    reset() {
      this.isLoading = false;
      this.hasMore = false;
      this.page = 1;
      this.posts = [];
    },
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        this.$refs.postsContainer &&
        scrollPosition &&
        this.$refs.postsContainer.offsetHeight + this.$refs.postsContainer.offsetTop - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadUser() {
      this.$get(
        '/users/' + this.username,
        (data) => {
          this.user = new User(data);
          this.loadPosts();
        },
        (errors) => {
          this.isUserNotFound = true;
          console.log(errors);
        },
      );
    },
    loadPosts() {
      this.isLoading = true;
      this.$get(
        '/posts/user/' +
        this.user.id +
        '?page=' +
        this.page +
        '&type=' +
        this.postsType,
        (data) => {
          let posts = [...this.posts];
          for (let obj of data.data) {
            posts.push(new Post(obj));
          }
          this.posts = posts;
          this.hasMore = data.next_page_url != null;
          this.isLoading = false;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadPosts();
      }
    },
    copyLink() {
      const link = `${process.env.VUE_APP_APP_URL}${this.user.url}`;
      this.$copyText(link);
      this.$bvToast.toast(this.$t('general.link-copied'), {
        autoHideDelay: 2000,
        title: this.$t('general.link'),
        solid: true,
        toaster: 'b-toaster-bottom-left',
      });
    },
    onBlock() {
      this.userToBlock = null;
      this.$router.replace('/');
    },
    subscribe() {
      if (this.user.isFree) {
        this.$post(
          '/subscribe/' + this.user.id,
          {},
          () => {
            this.reset();
            this.loadUser();
          },
          (errors) => {
            console.log(errors);
          },
        );
      } else {
        this.$buyItem({
          type: null,
          user: this.user,
        });
      }
    },
    tip() {
      this.$buyItem({
        type: Payment.TYPE_TIP,
        user: this.user,
      });
    },
  },
};
</script>
